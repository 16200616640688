export function inView() {

$(function () {
  $(".js-inview").on("inview", function (event, isInView) {
    $(this).stop().addClass("is-show");
    // if (isInView) {
    //   $(this).stop().addClass("is-show");
    // } else {
    //   $(this).stop().removeClass("is-show");
    // }
  });
});
}