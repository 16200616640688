// gsap.registerPlugin(ScrollTrigger);

// 読み込み
import { aLink } from './_a-link.js';
import { Header } from './_header.js';
import { HamNav } from './_ham.js';
import { inView } from './_inview.js';
import { footerFixed } from './_footer.js';


//呼び出し
Header();
aLink();
HamNav();
inView();
footerFixed();
