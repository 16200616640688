export function aLink() {

/*! アンカーリンク */
// ▼ヘッダー固定で高さが必要な時
const header = $('header');

$('a[href*="#"]').on('click', function(event) {
  const href = $(this).attr("href");
  const targetId = href.split('#')[1];
  const target = $('#' + targetId);

  if (target.length) {
    // ページ内リンクの場合
    event.preventDefault();
    const gap = header.outerHeight();
    const speed = 500;
    const position = target.offset().top - gap;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
  } else {
    // 別ページへのリンクの場合
    $(window).on('load', function() {
      const target = $('#' + targetId);
      if (target.length) {
        const gap = header.outerHeight();
        const position = target.offset().top - gap;
        $("html, body").animate({ scrollTop: position }, 500, "swing");
      }
    });
  }
});

// ページ読み込み時にハッシュがあればスクロール
$(window).on('load', function() {
  const hash = window.location.hash;
  if (hash) {
    const target = $(hash);
    if (target.length) {
      const gap = header.outerHeight();
      const position = target.offset().top - gap;
      $("html, body").animate({ scrollTop: position }, 500, "swing");
    }
  }
});
}